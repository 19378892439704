import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import editIcon from 'assets/svg/arrow-right.svg';
import ConfirmRow from 'components/form/ConfirmRow';
import ChoicePicker, { ChoiceOption } from 'components/inputs/ChoicePicker';
import DateInputField from 'components/inputs/DateInputField';
import DropdownField from 'components/inputs/DropdownField';
import NumericInputField from 'components/inputs/NumericInputField';
import TextArea from 'components/inputs/TextArea';
import TextInputField from 'components/inputs/TextInputField';
import LocationByZipPicker from 'components/location/LocationByZipPicker';
import { Text1 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { MAX_UNITS_QUANTITY, useAddItem } from 'features/add-item/context/AddItemProvider';
import { AddPhotosStepContent } from 'features/add-item/form/steps/AddPhotos';
import { SetItemDeliveryContent } from 'features/add-item/form/steps/SetItemDelivery';
import { SetPriceContent } from 'features/add-item/form/steps/SetPrice';
import { useCampaignPicker } from 'features/campaign/picker/useCampaignPicker';
import { useItemCategories, useItemConditions } from 'providers/cache/cachedDataHooks';
import { currentDate } from 'utils/backendDateParser';
import { recognizeTitle } from 'utils/titleRecognition';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  min-height: 400px;
`;

const CampaignName = styled(Text1)`
  padding-right: 24px;
`;

const EditIcon = styled.img`
  position: absolute;
  right: 0;
  padding: 12px;
  cursor: pointer;
`;

const ItemSummaryForm = () => {
  const { t } = useTranslation('addItem');
  const { formData, errors, setFieldValue, isLoading, isSaving, isUnitSold, isEditMode } = useAddItem();
  const { showCampaignPicker } = useCampaignPicker();
  const { itemConditions } = useItemConditions();
  const { itemCategories } = useItemCategories();

  useEffect(() => {
    !isEditMode && !formData.title && setFieldValue('title', recognizeTitle(formData.photos));
  }, []);

  const categoryOptions =
    itemCategories
      ?.filter(category => category.itemType === formData.type)
      .map(category => {
        return {
          value: category.code,
          label: category.displayName,
        };
      }) || [];

  const conditionOptions: ChoiceOption[] =
    itemConditions?.map(condition => {
      return { key: condition.code, name: condition.shortDisplayName, isActive: formData.condition === condition.code };
    }) || [];

  const resolveType = () => {
    if (formData.type === 'REGULAR') return t('contribute.type-regular.title');
    if (formData.type === 'SERVICE') return t('contribute.type-service.title');
  };

  const resolveTypeOfSale = () => {
    if (formData.sellingMethod === 'STANDARD') return t('type-of-sale.type-standard.title');
    if (formData.sellingMethod === 'AUCTION') return t('type-of-sale.type-auction.title');
    if (formData.sellingMethod === 'MULTIPLE') return t('type-of-sale.type-multiple.title');
  };

  if (isLoading || isSaving) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <ConfirmRow label={t('confirm.title')}>
        <TextInputField
          testId={'title-input'}
          value={formData.title}
          onChange={value => setFieldValue('title', value)}
          errorMessage={errors.title}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.description')}>
        <TextArea
          testId={'description-textarea'}
          value={formData.description || ''}
          placeholder={t('confirm.description-placeholder')}
          onChange={value => setFieldValue('description', value)}
          errorMessage={errors.description}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.type')}>
        <Text1>{resolveType()}</Text1>
      </ConfirmRow>
      <ConfirmRow label={t('confirm.category')}>
        <DropdownField
          testId={'category-select'}
          value={formData.category}
          options={categoryOptions}
          onChange={value => setFieldValue('category', value)}
          errorMessage={errors.category}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.type-of-sale')}>
        <Text1>{resolveTypeOfSale()}</Text1>
      </ConfirmRow>
      {formData.type === 'REGULAR' && (
        <ConfirmRow label={t('confirm.condition')}>
          <ChoicePicker options={conditionOptions} onSelect={value => setFieldValue('condition', value)} />
        </ConfirmRow>
      )}
      <ConfirmRow label={formData.sellingMethod === 'AUCTION' ? t('confirm.starting-price') : t('confirm.price')}>
        <SetPriceContent />
      </ConfirmRow>
      {formData.sellingMethod === 'MULTIPLE' && (
        <ConfirmRow label={t('confirm.quantity')}>
          <NumericInputField
            value={formData.unitsQuantity}
            onChange={value => setFieldValue('unitsQuantity', value)}
            errorMessage={errors.unitsQuantity}
            maxValue={MAX_UNITS_QUANTITY}
            testId={'quantity-input'}
          />
        </ConfirmRow>
      )}
      {formData.sellingMethod === 'AUCTION' && (
        <ConfirmRow label={t('confirm.end-date-time')}>
          <DateInputField
            value={formData.endDateTime}
            onChange={value => setFieldValue('endDateTime', value)}
            minDate={currentDate()}
            testId={'end-date-input'}
          />
        </ConfirmRow>
      )}
      {formData.type === 'REGULAR' && (
        <ConfirmRow label={t('delivery.regular.header')}>
          <SetItemDeliveryContent />
        </ConfirmRow>
      )}
      {formData.type === 'SERVICE' && (
        <ConfirmRow label={t('delivery.service.header')}>
          <LocationByZipPicker
            value={formData.itemLocation}
            onChange={value => setFieldValue('itemLocation', value)}
            validZipLength={5}
            placeholder={t('delivery.zip-placeholder')}
          />
        </ConfirmRow>
      )}
      <ConfirmRow label={t('confirm.supported-campaign')}>
        <CampaignName>{formData.supportedCampaign?.name}</CampaignName>
        {!isUnitSold && (
          <EditIcon
            data-testid={'edit-icon'}
            src={editIcon}
            onClick={() => showCampaignPicker(campaign => setFieldValue('supportedCampaign', campaign), true)}
          />
        )}
      </ConfirmRow>
      <ConfirmRow label={t('confirm.photos')}>
        <AddPhotosStepContent />
      </ConfirmRow>
    </Container>
  );
};

export default ItemSummaryForm;
