import { BecomeFundraiserRequestDto } from 'services/user/userService.dto';

import { BecomeFundraiserFormData } from './useBecomeFundraiserForm';

export const convertFormDataToBecomeFundraiserDto = (
  formData: BecomeFundraiserFormData
): BecomeFundraiserRequestDto => {
  return {
    fundraiserType: formData.fundraiserType!,
    accountNumber: formData.accountNumber,
    accountOrigin: formData.accountOrigin,
    accountType: formData.accountType,
    addressLine1: formData.addressLine1!,
    addressLine2: formData.addressLine2,
    city: formData.city!,
    country: formData.country!,
    ein: formData.ein,
    email: formData.email!,
    mobilePhone: formData.mobilePhone!,
    organizationName: formData.organizationName!,
    routingNumber: formData.routingNumber,
    state: formData.state!,
    zipCode: formData.zipCode!,
  };
};
