import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { CampaignDto, EditCampaignDto, NewCampaignDto } from 'services/campaign/campaignService.dto';
import { ImageDto, useUploadImages } from 'services/image/useUploadImages';
import { LocationDetailsDto } from 'services/utils/location/locationService.dto';
import { currentDate, parseBackendDate } from 'utils/backendDateParser';
import { isTheSameDay, startOfDay } from 'utils/dateUtils';
import { isVideoLinkValid } from 'utils/videoLinkValidation';

import {
  DESCRIPTION_LENGTH_LIMIT,
  MAX_CAMPAIGN_GOAL,
  MAX_PHOTO_SIZE,
  MAX_PHOTOS_COUNT,
  NAME_LENGTH_LIMIT,
} from './AddCampaignProvider';
import { convertFormDataToEditCampaignDto, convertFormDataToNewCampaignDto } from './converters';

export interface CampaignFormData {
  photos: ImageDto[];
  videoUrl?: string;
  moneyGoal?: number;
  name: string;
  campaignLocation?: LocationDetailsDto;
  description?: string;
  categories: string[];
  timelineFrom?: Date;
  timelineTo?: Date;
}

export const useCampaignForm = (
  postCampaign: (request: NewCampaignDto) => void,
  updateCampaign: (request: EditCampaignDto) => void,
  campaign?: CampaignDto
) => {
  const { t } = useTranslation('addCampaign');
  const { photos, addPhoto, deletePhoto, movePhoto } = useUploadImages('CAMPAIGN', campaign?.imageUrls, {
    maxNumberOfImages: MAX_PHOTOS_COUNT,
    maxFileSizeInBytes: MAX_PHOTO_SIZE,
  });

  useEffect(() => {
    formik.setFieldValue('photos', photos);
  }, [photos]);

  const onSubmit = (data: CampaignFormData) => {
    if (!!campaign) {
      const editCampaignRequest: EditCampaignDto = convertFormDataToEditCampaignDto(data, campaign.id);
      updateCampaign(editCampaignRequest);
    } else {
      const newCampaignRequest: NewCampaignDto = convertFormDataToNewCampaignDto(data);
      postCampaign(newCampaignRequest);
    }
  };

  const initialValues = useMemo((): CampaignFormData => {
    return {
      photos,
      videoUrl: campaign?.videoUrl || undefined,
      timelineFrom: campaign?.timelineFrom ? startOfDay(parseBackendDate(campaign.timelineFrom)) : undefined,
      timelineTo: campaign?.timelineTo ? startOfDay(parseBackendDate(campaign?.timelineTo)) : undefined,
      moneyGoal: campaign?.moneyGoal || undefined,
      campaignLocation: campaign?.campaignLocation,
      name: campaign?.name || '',
      description: campaign?.description || '',
      categories: campaign?.categories || [],
    };
  }, [photos, campaign]);

  const validationSchema = Yup.object().shape({
    photos: Yup.array()
      .min(1)
      .max(MAX_PHOTOS_COUNT)
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          isUploading: Yup.boolean(),
          safe: Yup.boolean(),
        })
      )
      .test('all-photos-uploaded', 'All photos must be uploaded', photos =>
        photos?.every(photo => photo.isUploading === false)
      )
      .test('all-photos-safe', 'All photos must be safe', photos => photos?.every(photo => photo.safe === true)),
    videoUrl: Yup.string()
      .trim()
      .url(t('common:invalid-url'))
      .test('videoUrlValid', t('add-photos.video-not-found'), value => (value ? isVideoLinkValid(value) : true)),
    timelineFrom: Yup.date()
      .required()
      .min(
        campaign && campaign.timelineFrom ? startOfDay(new Date(campaign.timelineFrom)) : startOfDay(currentDate()),
        'timelineFrom must be later than current date or original date'
      )
      .test('timelineFrom not modified', value =>
        campaign && campaign.timelineFrom ? isTheSameDay(value, new Date(campaign.timelineFrom)) : true
      ),
    timelineTo: Yup.date().nullable().min(Yup.ref('timelineFrom'), 'timelineTo must be later than timelineFrom'),
    moneyGoal: Yup.number()
      .positive(t('goal.negative-goal-error'))
      .max(MAX_CAMPAIGN_GOAL, t('goal.max-goal-error', { maxGoal: MAX_CAMPAIGN_GOAL })),
    campaignLocation: Yup.object().required(),
    name: Yup.string()
      .trim()
      .required(t('name.name-error-required'))
      .max(NAME_LENGTH_LIMIT, t('name.name-error-length', { maxNameLength: NAME_LENGTH_LIMIT })),
    description: Yup.string()
      .trim()
      .max(
        DESCRIPTION_LENGTH_LIMIT,
        t('description.description-error-length', { maxDescriptionLength: DESCRIPTION_LENGTH_LIMIT })
      ),
    categories: Yup.array().min(1, t('name.categories-error-required')),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return { ...formik, addPhoto, deletePhoto, movePhoto };
};
