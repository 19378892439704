import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';

const VerifyOrganization = () => {
  const { t } = useTranslation('myProfile');

  return (
    <FormStepWrapper
      header={t('become-fundraiser.verify-organization.header')}
      description={t('become-fundraiser.verify-organization.description')}
      isValid={true}>
      TODO
    </FormStepWrapper>
  );
};

export default VerifyOrganization;
