import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { FormikErrors } from 'formik';

import { useGlobalError } from 'hooks/useGlobalError';
import { userService } from 'services/user/userService';
import { BecomeFundraiserRequestDto, CurrentUserDto } from 'services/user/userService.dto';

import { BecomeFundraiserFormData, useBecomeFundraiserForm } from './useBecomeFundraiserForm';

interface BecomeFundraiserContextType {
  save: () => Promise<void>;
  isSaving: boolean;
  formData: BecomeFundraiserFormData;
  user: CurrentUserDto;
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<BecomeFundraiserFormData>;
  isFormValid: boolean;
}

const BecomeFundraiserContext = createContext<BecomeFundraiserContextType>(null!);

export interface BecomeFundraiserContextProps extends PropsWithChildren {
  user: CurrentUserDto;
  onSaved?: () => void;
}

const BecomeFundraiserProvider = ({ user, onSaved, children }: BecomeFundraiserContextProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { unknownError } = useGlobalError();

  const save = async (request: BecomeFundraiserRequestDto) => {
    setIsSaving(true);
    try {
      await userService.becomeFundraiser(request);
      onSaved && onSaved();
    } catch (error) {
      unknownError();
    } finally {
      setIsSaving(false);
    }
  };

  const becomeFundraiserForm = useBecomeFundraiserForm(save);

  return (
    <BecomeFundraiserContext.Provider
      value={{
        save: becomeFundraiserForm.submitForm,
        isSaving,
        formData: becomeFundraiserForm.values,
        user,
        isFormValid: becomeFundraiserForm.isValid && becomeFundraiserForm.dirty && !becomeFundraiserForm.isValidating,
        ...becomeFundraiserForm,
      }}>
      {children}
    </BecomeFundraiserContext.Provider>
  );
};

const useBecomeFundraiser = () => useContext(BecomeFundraiserContext);

export { BecomeFundraiserProvider, useBecomeFundraiser };
