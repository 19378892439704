import { useTranslation } from 'react-i18next';

import { useConfirmPopup } from 'components/popups/useConfirmPopup';
import { usePopup } from 'hooks/usePopup';
import { CurrentUserDto } from 'services/user/userService.dto';

import BecomeFundraiserForm from './BecomeFundraiserForm';

export const useBecomeFundraiserPopup = () => {
  const { t } = useTranslation('myProfile');
  const { showPopup, hidePopup } = usePopup();
  const { showConfirmPopup } = useConfirmPopup();

  const handleClose = () => showConfirmPopup({ msg: t('become-fundraiser.quit-form-msg'), onYes: hidePopup });

  const showBecomeFundraiserPopup = (user: CurrentUserDto) => {
    showPopup(<BecomeFundraiserForm user={user} />, { onClose: handleClose, paddingVariant: 'FORM' });
  };

  return { showBecomeFundraiserPopup };
};
