import styled from 'styled-components';

import { MultiStepFormProvider } from 'components/form/MultiStepFormProvider';
import {
  BecomeFundraiserContextProps,
  BecomeFundraiserProvider,
} from 'features/my-profile/become-fundraiser/context/BecomeFundraiserProvider';

import SelectType from './steps/SelectType';
import Summary from './steps/Summary';
import VerifyBankAccount from './steps/VerifyBankAccount';
import VerifyOrganization from './steps/VerifyOrganization';
import VerifyPhone from './steps/VerifyPhone';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const BecomeFundraiserForm = () => {
  return (
    <Container data-testid={'become-fundraiser-form'}>
      <MultiStepFormProvider>
        <SelectType />
        <VerifyPhone />
        <VerifyOrganization />
        <VerifyBankAccount />
        <Summary />
      </MultiStepFormProvider>
    </Container>
  );
};

const BecomeFundraiserFormWithContext = ({ user }: BecomeFundraiserContextProps) => {
  return (
    <BecomeFundraiserProvider user={user}>
      <BecomeFundraiserForm />
    </BecomeFundraiserProvider>
  );
};

export default BecomeFundraiserFormWithContext;
