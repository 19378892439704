import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

import { FormStepProps } from './form.types';
import FormProgressIndicator from './FormProgressIndicator';
import { useMultiStepForm } from './MultiStepFormProvider';

const Container = styled.div`
  width: 475px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${theme.mq.phone} {
    width: 100%;
  }
`;

const Description = styled(Text1)`
  margin: 24px 48px;
  white-space: pre-wrap;

  ${theme.mq.phone} {
    margin: 24px 10px;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  padding: 0 40px;

  ${theme.mq.phone} {
    width: 100%;
    padding: 0 10px;
  }
`;

const NextButton = styled(RedButton)`
  margin-top: 48px;
  height: 46px;
`;

const PrevButton = styled(RedTextButton)`
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
  padding: 0 30px;

  ${theme.mq.phone} {
    padding: 0;
  }
`;

const FormStepWrapper = (props: FormStepProps) => {
  const { t } = useTranslation();
  const { header, description, isNextHidden, isValid, onNextPress, children } = props;
  const { activeStepIndex, totalStepsCount, goToNextStep, goToPreviousStep } = useMultiStepForm();

  const isNextBtnVisible = !isNextHidden;
  const isPrevBtnVisible = activeStepIndex > 0;
  const isLastStep = activeStepIndex === totalStepsCount - 1;

  const handleNext = () => {
    if (onNextPress) onNextPress();
    else goToNextStep();
  };

  return (
    <Container data-testid={`form-step-${activeStepIndex}`}>
      <H4 data-testid={'form-header'}>{header}</H4>
      <FormProgressIndicator />
      {description && <Description data-testid={'form-description'}>{description}</Description>}
      <ContentContainer>{children}</ContentContainer>
      <ButtonsContainer>
        {isNextBtnVisible && (
          <NextButton data-testid={'form-next-btn'} disabled={!isValid} onClick={handleNext}>
            {isLastStep ? t('ready') : t('next')}
          </NextButton>
        )}
        {isPrevBtnVisible && (
          <PrevButton data-testid={'form-prev-btn'} onClick={goToPreviousStep}>
            {t('previous-step')}
          </PrevButton>
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default FormStepWrapper;
