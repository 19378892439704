import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { formatDateLong } from 'utils/dateFormatter';
import { isDateAfter, isDateBefore } from 'utils/dateUtils';

import { calendarStyles } from './calendar.styles';
import { RangeCalendarDay } from './RangeCalendarDay';

const CustomDatePicker = styled(StaticDatePicker)`
  ${calendarStyles}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 50%;
  align-self: end;
  gap: 10px;
  margin: 10px;
`;

const ActionButton = styled(RedButton)`
  height: 30px;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
`;

const HeaderContainer = styled.div`
  display: flex;
  background-color: ${theme.color.red};
  color: ${theme.color.white};
  height: 60px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  align-items: center;
  justify-content: center;
`;

interface Props {
  dateFrom?: Date;
  dateTo?: Date;
  onAccept: (startDate?: Date, endDate?: Date) => void;
  onClose: () => void;
  minDate?: Date;
  canEditDateFrom?: boolean;
}

export const RangeCalendar = ({ dateFrom, dateTo, onAccept, onClose, minDate, canEditDateFrom }: Props) => {
  const { t } = useTranslation('addCampaign');
  const [startDate, setStartDate] = useState<Date | undefined>(dateFrom);
  const [endDate, setEndDate] = useState<Date | undefined>(dateTo);

  const formatHeader = () => {
    if (!startDate) return t('timeline.custom-date-placeholder');
    return `${formatDateLong(startDate)} - ${endDate ? formatDateLong(endDate) : ''}`;
  };

  const handleChange = (inputValue: Dayjs | null) => {
    if (canEditDateFrom && (!startDate || !!endDate || isDateBefore(inputValue?.toDate(), startDate))) {
      setStartDate(inputValue?.toDate());
      setEndDate(undefined);
    } else {
      if (isDateAfter(inputValue?.toDate(), startDate)) setEndDate(inputValue?.toDate());
    }
  };

  return (
    <Container>
      <HeaderContainer>{formatHeader()}</HeaderContainer>
      <CustomDatePicker
        onChange={handleChange}
        views={['year', 'month', 'day']}
        slots={{ day: dayProps => <RangeCalendarDay {...dayProps} startDate={startDate} endDate={endDate} /> }}
        slotProps={{
          actionBar: { actions: [] },
          toolbar: { hidden: true },
        }}
        onClose={onClose}
        minDate={minDate && dayjs(minDate)}
      />
      <ButtonsContainer>
        <ActionButton onClick={onClose}>{t('common:cancel')}</ActionButton>
        <ActionButton onClick={() => onAccept(startDate, endDate)}>{t('common:ok')}</ActionButton>
      </ButtonsContainer>
    </Container>
  );
};
