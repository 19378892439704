import { AxiosPromise } from 'axios';

import { apiService, apiUrl } from 'services/utils/apiService';

import { ReasonDto } from './reportService.dto';

class ReportService {
  static BASE_URL = `${apiUrl}/api/report`;

  fetchReasons(): AxiosPromise<ReasonDto[]> {
    const url = `${ReportService.BASE_URL}/public/reasons`;
    return apiService.get(url);
  }
}

const reportService = new ReportService();

export { reportService, ReportService };
