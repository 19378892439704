import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AccountOrigin, AccountType, BecomeFundraiserRequestDto, FundraiserType } from 'services/user/userService.dto';

import {
  EIN_LENGTH,
  MAX_ADDRESS_LINE_LENGTH,
  MAX_BANK_ACCOUNT_NUMBER_LENGTH,
  MAX_EMAIL_LENGTH,
  MAX_ORGANIZATION_LENGTH,
  MAX_PHONE_LENGTH,
  MIN_BANK_ACCOUNT_NUMBER_LENGTH,
  MIN_ORGANIZATION_LENGTH,
  MIN_PHONE_LENGTH,
  ROUTING_NUMBER_LENGTH,
} from './becomeFundraiserUtils';
import { convertFormDataToBecomeFundraiserDto } from './converters';

export interface BecomeFundraiserFormData {
  fundraiserType?: FundraiserType;
  organizationName?: string;
  ein?: string;
  state?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  addressLine1?: string;
  addressLine2?: string;
  routingNumber?: string;
  accountNumber?: string;
  accountNumberConfirmation?: string;
  accountOrigin?: AccountOrigin;
  accountType?: AccountType;
  mobilePhone?: string;
  email?: string;
}

export const useBecomeFundraiserForm = (becomeFundraiser: (request: BecomeFundraiserRequestDto) => void) => {
  const { t } = useTranslation('myProfile');

  const onSubmit = (data: BecomeFundraiserFormData) => {
    const newBecomeFundraiserRequest: BecomeFundraiserRequestDto = convertFormDataToBecomeFundraiserDto(data);
    becomeFundraiser(newBecomeFundraiserRequest);
  };

  const validationSchema = Yup.object().shape({
    fundraiserType: Yup.string().required(),
    organizationName: Yup.string()
      .trim()
      .min(MIN_ORGANIZATION_LENGTH, t('form-inputs.organization-name-too-short'))
      .max(MAX_ORGANIZATION_LENGTH)
      .required(t('form-inputs.organization-name-required')),
    ein: Yup.string()
      .trim()
      .matches(/^\d+$/, t('form-inputs.not-allowed-chars'))
      .length(EIN_LENGTH, t('form-inputs.ein-invalid-length'))
      .required(t('form-inputs.ein-required')),
    state: Yup.string().trim().required(t('form-inputs.state-required')),
    zipCode: Yup.string().trim().required(t('form-inputs.zip-required')),
    city: Yup.string().trim().required(t('form-inputs.city-required')),
    country: Yup.string().trim().required(t('form-inputs.country-required')),
    addressLine1: Yup.string().trim().max(MAX_ADDRESS_LINE_LENGTH).required(t('form-inputs.address-required')),
    addressLine2: Yup.string().trim().max(MAX_ADDRESS_LINE_LENGTH),
    routingNumber: Yup.string()
      .trim()
      .matches(/^\d+$/, t('form-inputs.routing-number-invalid'))
      .length(ROUTING_NUMBER_LENGTH, t('form-inputs.routing-number-invalid')),
    accountNumber: Yup.string()
      .trim()
      .matches(/^\d+$/, t('form-inputs.not-allowed-chars'))
      .min(MIN_BANK_ACCOUNT_NUMBER_LENGTH, t('form-inputs.bank-account-number-too-short'))
      .max(MAX_BANK_ACCOUNT_NUMBER_LENGTH),
    accountNumberConfirmation: Yup.string().when('accountNumber', {
      is: (value: string) => !!value,
      then: schema =>
        schema
          .required(t('form-inputs.bank-account-number-no-match'))
          .oneOf([Yup.ref('accountNumber')], t('form-inputs.bank-account-number-no-match')),
    }),
    accountOrigin: Yup.string(),
    accountType: Yup.string(),
    mobilePhone: Yup.string()
      .trim()
      .matches(/^\+\d*$/, t('form-inputs.phone-invalid'))
      .min(MIN_PHONE_LENGTH, t('form-inputs.phone-too-short'))
      .max(MAX_PHONE_LENGTH)
      .required(t('form-inputs.phone-required')),
    email: Yup.string()
      .trim()
      .email(t('form-inputs.email-invalid'))
      .max(MAX_EMAIL_LENGTH)
      .required(t('form-inputs.email-required')),
  });

  const initialValues = {};

  return useFormik({ initialValues, validationSchema, onSubmit });
};
