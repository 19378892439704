export const MIN_ORGANIZATION_LENGTH = 3;
export const MAX_ORGANIZATION_LENGTH = 200;
export const EIN_LENGTH = 9;
export const MAX_ADDRESS_LINE_LENGTH = 34;
export const ROUTING_NUMBER_LENGTH = 9;
export const MIN_BANK_ACCOUNT_NUMBER_LENGTH = 6;
export const MAX_BANK_ACCOUNT_NUMBER_LENGTH = 17;
export const MIN_PHONE_LENGTH = 11;
export const MAX_PHONE_LENGTH = 25;
export const MAX_EMAIL_LENGTH = 50;
