import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';

const Summary = () => {
  const { t } = useTranslation('myProfile');

  return (
    <FormStepWrapper
      header={t('become-fundraiser.summary.header')}
      description={t('become-fundraiser.summary.description')}
      isValid={true}>
      TODO
    </FormStepWrapper>
  );
};

export default Summary;
