import { EditCampaignDto, NewCampaignDto } from 'services/campaign/campaignService.dto';
import { toBackendDate } from 'utils/backendDateParser';

import { CampaignFormData } from './useCampaignForm';

export const convertFormDataToNewCampaignDto = (formData: CampaignFormData): NewCampaignDto => {
  const imgUrls = formData.photos ? formData.photos.filter(photo => photo.safe).map(photo => photo.url || '') : [];
  return {
    categories: formData.categories,
    description: formData.description,
    imageUrls: imgUrls,
    videoUrl: formData.videoUrl || null,
    campaignLocation: formData.campaignLocation!,
    moneyGoal: formData.moneyGoal,
    name: formData.name,
    timelineFrom: toBackendDate(formData.timelineFrom!),
    timelineTo: formData.timelineTo ? toBackendDate(formData.timelineTo) : undefined,
  };
};

export const convertFormDataToEditCampaignDto = (formData: CampaignFormData, campaignId: number): EditCampaignDto => {
  return {
    ...convertFormDataToNewCampaignDto(formData),
    id: campaignId,
  };
};
